export const useMap = () => {
  let mymap = L.map("map").setView([49.13071101184796, 33.44291205692501], 14);

  L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGVwb2pla2EiLCJhIjoiY2tiOWx2eDRuMGZqMjJ4bzRndnUxcDY1NSJ9.Q4KnNwlVBSDCT1lMpucEJA",
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 20,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
      opacity: 1,
    }
  ).addTo(mymap);

  let marker = L.marker([49.13071101184796, 33.44291205692501]).addTo(mymap);
  let marker2 = L.marker([49.0790313389581, 33.423907238623094]).addTo(mymap);
  marker.bindPopup("<b>Охоронна компанія</b><br>Сокіл Гарант");
  marker2.bindPopup("<b>Охоронна компанія</b><br>Сокіл Гарант");
};

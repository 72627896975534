import Glide from "@glidejs/glide";

export const aboutPage = () => {
  const slider = document.getElementsByClassName("glide");

  const config = {
    type: "carousel",
    startAt: 1,
    perView: 1,
    autoplay: 6000,
    focusAt: "center",
    gap: 45,
    animationDuration: 600,
    breakpoints: {
      1090: {
        perView: 1,
      },

      750: {
        perView: 1,
      },
    },
  };
  if (slider[0] !== undefined) {
    new Glide(".glide", config).mount();
  }
};

import fullpage from "fullpage.js";
import { dotUse } from "./dots";
export const useFullPageScroll = (fullPage, header, sections, dots, video) => {
  if (fullPage !== null) {
    var path = window.location.pathname;
    //Full page scroll castomization
    new fullpage("#fullpage", {
      //options here
      autoScrolling: true,
      scrollHorizontally: true,

      onLeave: function (origin, destination, direction) {
        // console.log(origin);
        // console.log(destination);
        // console.log(direction);

        if (destination.item !== header) {
          destination.item.childNodes[0].classList.add(
            "animate__animated",
            // "animate__fadeInUp",
            "animate__zoomIn",
            "animate__fast"
          );
        }
        if (destination.item === header) {
          video.play();
        }
      },

      afterLoad: function (origin, destination, direction) {
        // console.log(destination.item.childNodes[0]);
        destination.item.childNodes[0].classList.remove(
          "animate__animated",
          // "animate__fadeInUp",
          "animate__zoomIn",
          "animate__fast"
        );
        dotUse(sections[0], destination, dots[0]);
        dotUse(sections[1], destination, dots[1]);
        dotUse(sections[2], destination, dots[2]);
        dotUse(sections[3], destination, dots[3]);
        dotUse(sections[4], destination, dots[4]);
        dotUse(sections[5], destination, dots[5]);
        dotUse(sections[6], destination, dots[6]);
        dotUse(sections[7], destination, dots[7]);
        dotUse(sections[8], destination, dots[8]);
      },
    });
    if (path === "/") {
      fullpage_api.setAllowScrolling(true);
    }
  }
};

export const toggleForm = ($formButton, $formExitButton, $formWrapper) => {
  document.addEventListener("click", (e) => {
    $formButton.childNodes.forEach((elem) => {
      if (
        e.target === $formButton ||
        e.target === $formExitButton ||
        e.target === $formWrapper ||
        e.target === elem
      ) {
        $formWrapper.classList.toggle("form__wrapper--shown");
      }
    });
  });
  if (!$formWrapper.classList.contains("form__wrapper--shown")) {
    $formWrapper.classList.add(
      "animate__animated",
      "animate__slideInRight",
      "animate__fast"
    );
  }
  if ($formWrapper.classList.contains("form__wrapper--shown")) {
    $formWrapper.classList.remove(
      "animate__animated",
      "animate__slideInRight",
      "animate__fast"
    );
  }
};

export const useForm = ($formWrapper) => {
  $("#submit").on("click", function (e) {
    e.preventDefault();
    const name = $("#name").val();
    const phone = $("#phone").val();
    $.ajax({
      url: "form.php",
      type: "post",
      data: { name: name, phone: phone },
      success: function (data) {
        $(".result").html(data);
        if (
          !(
            data ===
              '<span style="color:red">Данні заповнені не повністю!</span>' ||
            data ===
              '<span style="color:red">Данные заполенены не полностью или некорректно!</span>'
          )
        ) {
          setTimeout(() => {
            if ($formWrapper.classList.contains("form__wrapper--shown")) {
              $formWrapper.classList.remove("form__wrapper--shown");
            }
          }, 3000);
          $("#name").val("");
          $("#phone").val("");
        }
      },
    });
  });
};

export const useButton = (btn, elemToToggle, newElem, additionalButton) => {
  btn.addEventListener("click", (e) => {
    e.preventDefault();
    newElem.classList.add("animate__animated", "animate__fadeInRight");
    elemToToggle.classList.add("animate__animated", "animate__fadeInLeft");
    newElem.classList.toggle("hidden");
    elemToToggle.classList.toggle("hidden");
  });
  if (additionalButton) {
    additionalButton.addEventListener("click", (e) => {
      e.preventDefault();
      newElem.classList.add("animate__animated", "animate__fadeInRight");
      elemToToggle.classList.add("animate__animated", "animate__fadeInLeft");
      newElem.classList.toggle("hidden");
      elemToToggle.classList.toggle("hidden");
    });
  }
};

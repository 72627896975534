export const useBurger = (burgerIcon, burgerMenu, burgerMenuItem) => {
  burgerIcon.addEventListener("click", (e) => {
    for (let i = 0, child; (child = burgerIcon.children[i]); i++) {
      if (e.target === child || e.target === burgerIcon) {
        burgerIcon.classList.toggle("burger__active");
        burgerMenu.classList.toggle("burger__showed");
      }
    }
  });

  burgerMenuItem[0].addEventListener("click", (e) => {
    if (
      e.target === burgerMenuItem[0] ||
      e.target === burgerMenuItem[0].children[0]
    ) {
      burgerIcon.classList.toggle("burger__active");
      burgerMenu.classList.toggle("burger__showed");
    }
  });
};

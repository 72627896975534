export const useAccordion = (contents, buttons) => {
  buttons.forEach((elem, idx) => {
    elem.addEventListener("click", (e) => {
      e.preventDefault();
      contents.forEach((content) => {
        content.classList.add("hidden");
      });

      if (e.target === elem) {
        contents[idx].classList.toggle("hidden");
      }
    });
  });
};
export const useSingleAccordion = (content, button) => {
  button.addEventListener("click", (e) => {
    e.preventDefault();
    content.classList.toggle("hidden");
  });
};

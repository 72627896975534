export const makeNewsIframeAdaptive = (pixels, scale) => {
  if (window.matchMedia(`(min-width: ${pixels}px)`).matches) {
    return;
  } else {
    const $fbbox = document.querySelectorAll(".partners__news-fb");
    $fbbox.forEach((elem, idx) => {
      elem.style.transform = `scale(${scale})`;
    });
  }
};

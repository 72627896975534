export const dotUse = (item, destination, dot) => {
  if (item === destination.item) {
    dot.style.backgroundColor = "#0065ff";
    dot.style.transform = "scale(1.5)";
  } else {
    dot.style.backgroundColor = "transparent";
    dot.style.transform = "scale(1)";
  }

  window.location.hash = "";
};

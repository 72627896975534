import { useForm, toggleForm } from "./form";
import { aboutPage } from "./glide";
import { useBurger } from "./burger";
import { useButton } from "./useButton";
import { useFullPageScroll } from "./fullPageScroll";
import { changeVideoSpeed } from "./videoSpeed";
import { makeNewsIframeAdaptive } from "./adaptiveFrames";
import { useAccordion, useSingleAccordion } from "./accordion";
import { useMap } from "./useMap";
import { clickEmulator } from "./clickEmulator";
import { fadeRelocate } from "./fadeRelocate";

const $header = document.getElementById("header");

const $headerButton = document.getElementById("header-button");
const $headerTitle = document.getElementById("header-title");
const $headerServices = document.getElementById("header-services");
const $dots = document.querySelectorAll(".dots__item");
const $sections = document.querySelectorAll(".section");
const $fullPage = document.getElementById("fullpage");
const $video = document.getElementById("myVideo");
const $burgerIcon = document.getElementById("burger-icon");
const $burgerMenu = document.getElementById("burger-menu");
const $burgerMenuItem = document.querySelectorAll(".burger__item");
const firstButtons = document.querySelectorAll(".first__subtitle");
const firstContent = document.querySelectorAll(".first__text");
const $headerContentButton = document.getElementById("header-content-button");
const $headerContentExitButton = document.querySelector(
  ".services__exit-button"
);
const $headerContent = document.getElementById("header-content");
const $aboutContentButton = document.getElementById("about-content-button");
const $aboutContent = document.getElementById("about-content");
const secondButtons = document.querySelectorAll(".second__subtitle");
const secondContent = document.querySelectorAll(".second__text");
const thirdButtons = document.querySelector(".third__subtitle");
const thirdContent = document.querySelector(".third__text");
const fourthButtons = document.querySelector(".fourth__subtitle");
const fourthContent = document.querySelector(".fourth__text");
const fivethButtons = document.querySelector(".fiveth__subtitle");
const fivethContent = document.querySelector(".fiveth__text");
const sixthButtons = document.querySelector(".sixth__subtitle");
const sixthContent = document.querySelector(".sixth__text");
const seventhButtons = document.querySelectorAll(".seventh__subtitle");
const seventhContent = document.querySelectorAll(".seventh__text");
const eightButtons = document.querySelector(".eight__subtitle");
const eightContent = document.querySelector(".eight__text");
const $formWrapper = document.querySelector(".form__wrapper");
const $formExitButton = document.querySelector(".form__exit-button");
const $formButton = document.querySelector(".form-button");

const map = document.getElementById("map");

document.addEventListener("DOMContentLoaded", () => {
  

  if (map !== null) {
    useMap();
  }
  changeVideoSpeed($video);
  useBurger($burgerIcon, $burgerMenu, $burgerMenuItem);
  useFullPageScroll($fullPage, $header, $sections, $dots, $video);
  aboutPage();
  if ($headerButton !== null) {
    useButton(
      $headerButton,
      $headerTitle,
      $headerServices,
      $headerContentExitButton
    );
    useSingleAccordion(fourthContent, fourthButtons);
    useSingleAccordion(fivethContent, fivethButtons);
    useSingleAccordion($headerContent, $headerContentButton);
    useSingleAccordion(sixthContent, sixthButtons);
    useSingleAccordion(thirdContent, thirdButtons);
    useSingleAccordion(eightContent, eightButtons);
  }
  if ($aboutContentButton !== null) {
    useSingleAccordion($aboutContent, $aboutContentButton);
  }
  makeNewsIframeAdaptive(768, 0.8);
  makeNewsIframeAdaptive(480, 0.6);
  makeNewsIframeAdaptive(370, 0.5);
  useAccordion(firstContent, firstButtons);
  useAccordion(secondContent, secondButtons);
  useAccordion(seventhContent, seventhButtons);
  if ($headerContentButton) {
    clickEmulator($headerContentButton, 3, $headerContent);
  }
  fadeRelocate();

  useForm($formWrapper);
  toggleForm($formButton, $formExitButton, $formWrapper);
});
